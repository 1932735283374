import hallImg from '../Assets/Hall/hall1.jpg'
import kamin1 from '../Assets/Hall/kamin1.jpg'
import kamin2 from '../Assets/Hall/kamin2.jpg'
import kamin3 from '../Assets/Hall/kamin3.jpg'
import kamin4 from '../Assets/Hall/kamin4.jpg'
import kamin5 from '../Assets/Hall/kamin5.jpg'
import kamin6 from '../Assets/Hall/kamin6.jpg'
import kamin7 from '../Assets/Hall/kamin7.jpg'
import kamin8 from '../Assets/Hall/kamin8.jpg'
import kamin9 from '../Assets/Hall/kamin9.jpg'
import kamin10 from '../Assets/Hall/kamin10.jpg'
import kamin11 from '../Assets/Hall/kamin11.jpg'
import kamin12 from '../Assets/Hall/kamin12.jpg'
import kamin13 from '../Assets/Hall/kamin13.jpg'
import kamin14 from '../Assets/Hall/kamin14.jpg'
import kamin15 from '../Assets/Hall/kamin15.jpg'
import kamin16 from '../Assets/Hall/kamin16.jpg'
import kamin17 from '../Assets/Hall/kamin17.jpg'
import kamin18 from '../Assets/Hall/kamin18.jpg'
import kamin19 from '../Assets/Hall/kamin19.jpg'
import kamin20 from '../Assets/Hall/kamin20.jpg'
import kamin21 from '../Assets/Hall/kamin21.jpg'
import kamin22 from '../Assets/Hall/kamin22.jpg'
import kamin23 from '../Assets/Hall/kamin23.jpg'
import kamin24 from '../Assets/Hall/kamin24.jpg'
import kamin25 from '../Assets/Hall/kamin25.jpg'
import kamin26 from '../Assets/Hall/kamin26.jpg'
import kamin27 from '../Assets/Hall/kamin27.jpg'
import kamin28 from '../Assets/Hall/kamin28.jpg'
import kamin29 from '../Assets/Hall/kamin29.jpg'
import kamin30 from '../Assets/Hall/kamin30.jpg'
import kamin31 from '../Assets/Hall/kamin31.jpg'
import kamin32 from '../Assets/Hall/kamin32.jpg'
import kamin33 from '../Assets/Hall/kamin33.jpg'
import kamin34 from '../Assets/Hall/kamin34.jpg'
import kamin35 from '../Assets/Hall/kamin35.jpg'
import kamin36 from '../Assets/Hall/kamin36.jpg'
import kamin37 from '../Assets/Hall/kamin37.jpg'
import kamin38 from '../Assets/Hall/kamin38.jpg'
import kamin39 from '../Assets/Hall/kamin39.jpg'
import kamin40 from '../Assets/Hall/kamin40.jpg'
import kamin41 from '../Assets/Hall/kamin41.jpg'
import kamin42 from '../Assets/Hall/kamin42.jpg'
import kamin43 from '../Assets/Hall/kamin43.jpg'
import kamin44 from '../Assets/Hall/kamin44.jpg'
import kamin45 from '../Assets/Hall/kamin45.jpg'
import kamin46 from '../Assets/Hall/kamin46.jpg'
import kamin47 from '../Assets/Hall/kamin47.jpg'
import kamin48 from '../Assets/Hall/kamin48.jpg'
import kamin49 from '../Assets/Hall/kamin49.jpg'
import kamin50 from '../Assets/Hall/kamin50.jpg'
import kamin51 from '../Assets/Hall/kamin51.jpg'
import kamin52 from '../Assets/Hall/kamin52.jpg'
import svecana1 from '../Assets/Hall/svecana1.jpg'
import svecana2 from '../Assets/Hall/svecana2.jpg'
import svecana3 from '../Assets/Hall/svecana3.jpg'
import svecana4 from '../Assets/Hall/svecana4.jpg'
import svecana5 from '../Assets/Hall/svecana5.jpg'
import svecana6 from '../Assets/Hall/svecana6.jpg'
import svecana7 from '../Assets/Hall/svecana7.jpg'
import svecana8 from '../Assets/Hall/svecana8.jpg'
import svecana9 from '../Assets/Hall/svecana9.jpg'
import svecana10 from '../Assets/Hall/svecana10.jpg'
import svecana11 from '../Assets/Hall/svecana11.jpg'
import svecana12 from '../Assets/Hall/svecana12.jpg'
import svecana13 from '../Assets/Hall/svecana13.jpg'
import svecana14 from '../Assets/Hall/svecana14.jpg'
import svecana15 from '../Assets/Hall/svecana15.jpg'
import svecana16 from '../Assets/Hall/svecana16.jpg'
import svecana17 from '../Assets/Hall/svecana17.jpg'
import svecana18 from '../Assets/Hall/svecana18.jpg'
import svecana19 from '../Assets/Hall/svecana19.jpg'
import svecana20 from '../Assets/Hall/svecana20.jpg'
import svecana21 from '../Assets/Hall/svecana21.jpg'
import svecana22 from '../Assets/Hall/svecana22.jpg'
import svecana23 from '../Assets/Hall/svecana23.jpg'
import svecana24 from '../Assets/Hall/svecana24.jpg'
import svecana25 from '../Assets/Hall/svecana25.jpg'
import svecana26 from '../Assets/Hall/svecana26.jpg'
import svecana27 from '../Assets/Hall/svecana27.jpg'
import svecana28 from '../Assets/Hall/svecana28.jpg'
import svecana29 from '../Assets/Hall/svecana29.jpg'
import svecana30 from '../Assets/Hall/svecana30.jpg'
import svecana31 from '../Assets/Hall/svecana31.jpg'
import svecana32 from '../Assets/Hall/svecana32.jpg'
import svecana33 from '../Assets/Hall/svecana33.jpg'
import svecana34 from '../Assets/Hall/svecana34.jpg'
import svecana35 from '../Assets/Hall/svecana35.jpg'
import svecana36 from '../Assets/Hall/svecana36.jpg'
import svecana37 from '../Assets/Hall/svecana37.jpg'
import svecana38 from '../Assets/Hall/svecana38.jpg'
import svecana39 from '../Assets/Hall/svecana39.jpg'
import svecana40 from '../Assets/Hall/svecana40.jpg'
import svecana41 from '../Assets/Hall/svecana41.jpg'
import svecana42 from '../Assets/Hall/svecana42.jpg'
import svecana43 from '../Assets/Hall/svecana43.jpg'
import svecana44 from '../Assets/Hall/svecana44.jpg'
import svecana45 from '../Assets/Hall/svecana45.jpg'
import svecana46 from '../Assets/Hall/svecana46.jpg'
import svecana47 from '../Assets/Hall/svecana47.jpg'
import svecana48 from '../Assets/Hall/svecana48.jpg'
import svecana49 from '../Assets/Hall/svecana49.jpg'
import svecana50 from '../Assets/Hall/svecana50.jpg'
import svecana51 from '../Assets/Hall/svecana51.jpg'
import svecana52 from '../Assets/Hall/svecana52.jpg'
import svecana53 from '../Assets/Hall/svecana53.jpg'
import svecana54 from '../Assets/Hall/svecana54.jpg'
import svecana55 from '../Assets/Hall/svecana55.jpg'
import svecana56 from '../Assets/Hall/svecana56.jpg'
import svecana57 from '../Assets/Hall/svecana57.jpg'
import svecana58 from '../Assets/Hall/svecana58.jpg'
import svecana59 from '../Assets/Hall/svecana59.jpg'
import svecana60 from '../Assets/Hall/svecana60.jpg'
import svecana61 from '../Assets/Hall/svecana61.jpg'
import svecana62 from '../Assets/Hall/svecana62.jpg'
import svecana63 from '../Assets/Hall/svecana63.jpg'
import svecana64 from '../Assets/Hall/svecana64.jpg'
import svecana65 from '../Assets/Hall/svecana65.jpg'
import svecana66 from '../Assets/Hall/svecana66.jpg'
import svecana67 from '../Assets/Hall/svecana67.jpg'
import svecana68 from '../Assets/Hall/svecana68.jpg'
import svecana69 from '../Assets/Hall/svecana69.jpg'
import svecana70 from '../Assets/Hall/svecana70.jpg'
import svecana71 from '../Assets/Hall/svecana71.jpg'
import svecana72 from '../Assets/Hall/svecana72.jpg'
import svecana73 from '../Assets/Hall/svecana73.jpg'
import grande1 from '../Assets/Hall/grande1.jpg'
import grande2 from '../Assets/Hall/grande2.jpg'
import grande3 from '../Assets/Hall/grande3.jpg'
import grande4 from '../Assets/Hall/grande4.jpg'
import grande5 from '../Assets/Hall/grande5.jpg'
import grande6 from '../Assets/Hall/grande6.jpg'
import grande7 from '../Assets/Hall/grande7.jpg'
import grande8 from '../Assets/Hall/grande8.jpg'
import grande9 from '../Assets/Hall/grande9.jpg'
import grande10 from '../Assets/Hall/grande10.jpg'
import grande11 from '../Assets/Hall/grande11.jpg'
import grande12 from '../Assets/Hall/grande12.jpg'
import grande13 from '../Assets/Hall/grande13.jpg'
import grande14 from '../Assets/Hall/grande14.jpg'
import grande15 from '../Assets/Hall/grande15.jpg'
import grande16 from '../Assets/Hall/grande16.jpg'
import grande17 from '../Assets/Hall/grande17.jpg'
import grande18 from '../Assets/Hall/grande18.jpg'
import grande19 from '../Assets/Hall/grande19.jpg'
import grande20 from '../Assets/Hall/grande20.jpg'
import grande21 from '../Assets/Hall/grande21.jpg'
import grande22 from '../Assets/Hall/grande22.jpg'
import grande23 from '../Assets/Hall/grande23.jpg'
import grande24 from '../Assets/Hall/grande24.jpg'
import grande25 from '../Assets/Hall/grande25.jpg'
import grande26 from '../Assets/Hall/grande26.jpg'
import grande27 from '../Assets/Hall/grande27.jpg'
import grande28 from '../Assets/Hall/grande28.jpg'
import grande29 from '../Assets/Hall/grande29.jpg'
import grande30 from '../Assets/Hall/grande30.jpg'
import grande31 from '../Assets/Hall/grande31.jpg'
import grande32 from '../Assets/Hall/grande32.jpg'
import grande33 from '../Assets/Hall/grande33.jpg'
import grande34 from '../Assets/Hall/grande34.jpg'
import grande35 from '../Assets/Hall/grande35.jpg'
import grande36 from '../Assets/Hall/grande36.jpg'
import grande37 from '../Assets/Hall/grande37.jpg'
import grande38 from '../Assets/Hall/grande38.jpg'
import grande39 from '../Assets/Hall/grande39.jpg'
import grande40 from '../Assets/Hall/grande40.jpg'
import grande41 from '../Assets/Hall/grande41.jpg'
import grande42 from '../Assets/Hall/grande42.jpg'
import grande43 from '../Assets/Hall/grande43.jpg'
import grande44 from '../Assets/Hall/grande44.jpg'
import grande45 from '../Assets/Hall/grande45.jpg'
import grande46 from '../Assets/Hall/grande46.jpg'
import grande47 from '../Assets/Hall/grande47.jpg'
import grande48 from '../Assets/Hall/grande48.jpg'
import grande49 from '../Assets/Hall/grande49.jpg'
import grande50 from '../Assets/Hall/grande50.jpg'
import grande51 from '../Assets/Hall/grande51.jpg'
import grande52 from '../Assets/Hall/grande52.jpg'
import grande53 from '../Assets/Hall/grande53.jpg'
import grande54 from '../Assets/Hall/grande54.jpg'
import grande55 from '../Assets/Hall/grande55.jpg'
import grande56 from '../Assets/Hall/grande56.jpg'
import grande57 from '../Assets/Hall/grande57.jpg'
import grande58 from '../Assets/Hall/grande58.jpg'
import grande59 from '../Assets/Hall/grande59.jpg'
import grande60 from '../Assets/Hall/grande60.jpg'
import grande61 from '../Assets/Hall/grande61.jpg'
import grande62 from '../Assets/Hall/grande62.jpg'
import grande63 from '../Assets/Hall/grande63.jpg'
import grande64 from '../Assets/Hall/grande64.jpg'
import grande65 from '../Assets/Hall/grande65.jpg'
import grande66 from '../Assets/Hall/grande66.jpg'
import grande67 from '../Assets/Hall/grande67.jpg'
import grande68 from '../Assets/Hall/grande68.jpg'
import grande69 from '../Assets/Hall/grande69.jpg'
import grande70 from '../Assets/Hall/grande70.jpg'
import grande71 from '../Assets/Hall/grande71.jpg'
import grande72 from '../Assets/Hall/grande72.jpg'
import grande73 from '../Assets/Hall/grande73.jpg'
import grande74 from '../Assets/Hall/grande74.jpg'
import grande75 from '../Assets/Hall/grande75.jpg'
import grande76 from '../Assets/Hall/grande76.jpg'
import grande77 from '../Assets/Hall/grande77.jpg'
import grande78 from '../Assets/Hall/grande78.jpg'
import grande79 from '../Assets/Hall/grande79.jpg'
import grande80 from '../Assets/Hall/grande80.jpg'
import grande81 from '../Assets/Hall/grande81.jpg'
import grande82 from '../Assets/Hall/grande82.jpg'
import grande83 from '../Assets/Hall/grande83.jpg'
import grande84 from '../Assets/Hall/grande84.jpg'
import grande85 from '../Assets/Hall/grande85.jpg'
import grande86 from '../Assets/Hall/grande86.jpg'
import grande87 from '../Assets/Hall/grande87.jpg'
import grande88 from '../Assets/Hall/grande88.jpg'
import grande89 from '../Assets/Hall/grande89.jpg'
import grande90 from '../Assets/Hall/grande90.jpg'
import grande91 from '../Assets/Hall/grande91.jpg'
import grande92 from '../Assets/Hall/grande92.jpg'
import grande93 from '../Assets/Hall/grande93.jpg'
import grande94 from '../Assets/Hall/grande94.jpg'
import grande95 from '../Assets/Hall/grande95.jpg'
import grande96 from '../Assets/Hall/grande96.jpg'
import grande97 from '../Assets/Hall/grande97.jpg'
import grande98 from '../Assets/Hall/grande98.jpg'
import grande99 from '../Assets/Hall/grande99.jpg'
import grande100 from '../Assets/Hall/grande100.jpg'
import grande101 from '../Assets/Hall/grande101.jpg'
import grande102 from '../Assets/Hall/grande102.jpg'
import grande103 from '../Assets/Hall/grande103.jpg'
import grande104 from '../Assets/Hall/grande104.jpg'
import grande105 from '../Assets/Hall/grande105.jpg'
import grande106 from '../Assets/Hall/grande106.jpg'
import grande107 from '../Assets/Hall/grande107.jpg'
import grande108 from '../Assets/Hall/grande108.jpg'
import grande109 from '../Assets/Hall/grande109.jpg'
import grande110 from '../Assets/Hall/grande110.jpg'
import grande111 from '../Assets/Hall/grande111.jpg'
import grande112 from '../Assets/Hall/grande112.jpg'
import grande113 from '../Assets/Hall/grande113.jpg'

export const HallGalleryData = [
    {
        image: grande1,
        category: "grande"
    },
    {
        image: grande2,
        category: "grande"
    },
    {
        image: grande3,
        category: "grande"
    },
    {
        image: grande4,
        category: "grande"
    },
    {
        image: grande5,
        category: "grande"
    },
    {
        image: grande6,
        category: "grande"
    },
    {
        image: grande7,
        category: "grande"
    },
    {
        image: grande8,
        category: "grande"
    },
    {
        image: grande9,
        category: "grande"
    },
    {
        image: grande10,
        category: "grande"
    },
    {
        image: grande11,
        category: "grande"
    },
    {
        image: grande12,
        category: "grande"
    },
    {
        image: grande13,
        category: "grande"
    },
    {
        image: grande14,
        category: "grande"
    },
    {
        image: grande15,
        category: "grande"
    },
    {
        image: grande16,
        category: "grande"
    },
    {
        image: grande17,
        category: "grande"
    },
    {
        image: grande18,
        category: "grande"
    },
    {
        image: grande19,
        category: "grande"
    },
    {
        image: grande20,
        category: "grande"
    },
    {
        image: grande21,
        category: "grande"
    },
    {
        image: grande22,
        category: "grande"
    },
    {
        image: grande23,
        category: "grande"
    },
    {
        image: grande24,
        category: "grande"
    },
    {
        image: grande25,
        category: "grande"
    },
    {
        image: grande26,
        category: "grande"
    },
    {
        image: grande27,
        category: "grande"
    },
    {
        image: grande28,
        category: "grande"
    },
    {
        image: grande29,
        category: "grande"
    },
    {
        image: grande30,
        category: "grande"
    },
    {
        image: grande31,
        category: "grande"
    },
    {
        image: grande32,
        category: "grande"
    },
    {
        image: grande33,
        category: "grande"
    },
    {
        image: grande34,
        category: "grande"
    },
    {
        image: grande35,
        category: "grande"
    },
    {
        image: grande36,
        category: "grande"
    },
    {
        image: grande37,
        category: "grande"
    },
    {
        image: grande38,
        category: "grande"
    },
    {
        image: grande39,
        category: "grande"
    },
    {
        image: grande40,
        category: "grande"
    },
    {
        image: grande41,
        category: "grande"
    },
    {
        image: grande42,
        category: "grande"
    },
    {
        image: grande43,
        category: "grande"
    },
    {
        image: grande44,
        category: "grande"
    },
    {
        image: grande45,
        category: "grande"
    },
    {
        image: grande46,
        category: "grande"
    },
    {
        image: grande47,
        category: "grande"
    },
    {
        image: grande48,
        category: "grande"
    },
    {
        image: grande49,
        category: "grande"
    },
    {
        image: grande50,
        category: "grande"
    },
    {
        image: grande51,
        category: "grande"
    },
    {
        image: grande52,
        category: "grande"
    },
    {
        image: grande53,
        category: "grande"
    },
    {
        image: grande54,
        category: "grande"
    },
    {
        image: grande55,
        category: "grande"
    },
    {
        image: grande56,
        category: "grande"
    },
    {
        image: grande57,
        category: "grande"
    },
    {
        image: grande58,
        category: "grande"
    },
    {
        image: grande59,
        category: "grande"
    },
    {
        image: grande60,
        category: "grande"
    },
    {
        image: grande61,
        category: "grande"
    },
    {
        image: grande62,
        category: "grande"
    },
    {
        image: grande63,
        category: "grande"
    },
    {
        image: grande64,
        category: "grande"
    },
    {
        image: grande65,
        category: "grande"
    },
    {
        image: grande66,
        category: "grande"
    },
    {
        image: grande67,
        category: "grande"
    },
    {
        image: grande68,
        category: "grande"
    },
    {
        image: grande69,
        category: "grande"
    },
    {
        image: grande70,
        category: "grande"
    },
    {
        image: grande71,
        category: "grande"
    },
    {
        image: grande72,
        category: "grande"
    },
    {
        image: grande73,
        category: "grande"
    },
    {
        image: grande74,
        category: "grande"
    },
    {
        image: grande75,
        category: "grande"
    },
    {
        image: grande76,
        category: "grande"
    },
    {
        image: grande77,
        category: "grande"
    },
    {
        image: grande78,
        category: "grande"
    },
    {
        image: grande79,
        category: "grande"
    },
    {
        image: grande80,
        category: "grande"
    },
    {
        image: grande81,
        category: "grande"
    },
    {
        image: grande82,
        category: "grande"
    },
    {
        image: grande83,
        category: "grande"
    },
    {
        image: grande84,
        category: "grande"
    },
    {
        image: grande85,
        category: "grande"
    },
    {
        image: grande86,
        category: "grande"
    },
    {
        image: grande87,
        category: "grande"
    },
    {
        image: grande88,
        category: "grande"
    },
    {
        image: grande89,
        category: "grande"
    },
    {
        image: grande90,
        category: "grande"
    },
    {
        image: grande91,
        category: "grande"
    },
    {
        image: grande92,
        category: "grande"
    },
    {
        image: grande93,
        category: "grande"
    },
    {
        image: grande94,
        category: "grande"
    },
    {
        image: grande95,
        category: "grande"
    },
    {
        image: grande96,
        category: "grande"
    },
    {
        image: grande97,
        category: "grande"
    },
    {
        image: grande98,
        category: "grande"
    },
    {
        image: grande99,
        category: "grande"
    },
    {
        image: grande100,
        category: "grande"
    },
    {
        image: grande101,
        category: "grande"
    },
    {
        image: grande102,
        category: "grande"
    },
    {
        image: grande103,
        category: "grande"
    },
    {
        image: grande104,
        category: "grande"
    },
    {
        image: grande105,
        category: "grande"
    },
    {
        image: grande106,
        category: "grande"
    },
    {
        image: grande107,
        category: "grande"
    },
    {
        image: grande108,
        category: "grande"
    },
    {
        image: grande109,
        category: "grande"
    },
    {
        image: grande110,
        category: "grande"
    },
    {
        image: grande111,
        category: "grande"
    },
    {
        image: grande112,
        category: "grande"
    },
    {
        image: grande113,
        category: "grande"
    },
    {
        image: svecana1,
        category: "svečana"
    },
    {
        image: svecana2,
        category: "svečana"
    },
    {
        image: svecana3,
        category: "svečana"
    },
    {
        image: svecana4,
        category: "svečana"
    },
    {
        image: svecana5,
        category: "svečana"
    },
    {
        image: svecana6,
        category: "svečana"
    },
    {
        image: svecana7,
        category: "svečana"
    },
    {
        image: svecana8,
        category: "svečana"
    },
    {
        image: svecana9,
        category: "svečana"
    },
    {
        image: svecana10,
        category: "svečana"
    },
    {
        image: svecana11,
        category: "svečana"
    },
    {
        image: svecana12,
        category: "svečana"
    },
    {
        image: svecana13,
        category: "svečana"
    },
    {
        image: svecana14,
        category: "svečana"
    },
    {
        image: svecana15,
        category: "svečana"
    },
    {
        image: svecana16,
        category: "svečana"
    },
    {
        image: svecana17,
        category: "svečana"
    },
    {
        image: svecana18,
        category: "svečana"
    },
    {
        image: svecana19,
        category: "svečana"
    },
    {
        image: svecana20,
        category: "svečana"
    },
    {
        image: svecana21,
        category: "svečana"
    },
    {
        image: svecana22,
        category: "svečana"
    },
    {
        image: svecana23,
        category: "svečana"
    },
    {
        image: svecana24,
        category: "svečana"
    },
    {
        image: svecana25,
        category: "svečana"
    },
    {
        image: svecana26,
        category: "svečana"
    },
    {
        image: svecana27,
        category: "svečana"
    },
    {
        image: svecana28,
        category: "svečana"
    },
    {
        image: svecana29,
        category: "svečana"
    },
    {
        image: svecana30,
        category: "svečana"
    },
    {
        image: svecana31,
        category: "svečana"
    },
    {
        image: svecana32,
        category: "svečana"
    },
    {
        image: svecana33,
        category: "svečana"
    },
    {
        image: svecana34,
        category: "svečana"
    },
    {
        image: svecana35,
        category: "svečana"
    },
    {
        image: svecana36,
        category: "svečana"
    },
    {
        image: svecana37,
        category: "svečana"
    },
    {
        image: svecana38,
        category: "svečana"
    },
    {
        image: svecana39,
        category: "svečana"
    },
    {
        image: svecana40,
        category: "svečana"
    },
    {
        image: svecana41,
        category: "svečana"
    },
    {
        image: svecana42,
        category: "svečana"
    },
    {
        image: svecana43,
        category: "svečana"
    },
    {
        image: svecana44,
        category: "svečana"
    },
    {
        image: svecana45,
        category: "svečana"
    },
    {
        image: svecana46,
        category: "svečana"
    },
    {
        image: svecana47,
        category: "svečana"
    },
    {
        image: svecana48,
        category: "svečana"
    },
    {
        image: svecana49,
        category: "svečana"
    },
    {
        image: svecana50,
        category: "svečana"
    },
    {
        image: svecana51,
        category: "svečana"
    },
    {
        image: svecana52,
        category: "svečana"
    },
    {
        image: svecana53,
        category: "svečana"
    },
    {
        image: svecana54,
        category: "svečana"
    },
    {
        image: svecana55,
        category: "svečana"
    },
    {
        image: svecana56,
        category: "svečana"
    },
    {
        image: svecana57,
        category: "svečana"
    },
    {
        image: svecana58,
        category: "svečana"
    },
    {
        image: svecana59,
        category: "svečana"
    },
    {
        image: svecana60,
        category: "svečana"
    },
    {
        image: svecana61,
        category: "svečana"
    },
    {
        image: svecana62,
        category: "svečana"
    },
    {
        image: svecana63,
        category: "svečana"
    },
    {
        image: svecana64,
        category: "svečana"
    },
    {
        image: svecana65,
        category: "svečana"
    },
    {
        image: svecana66,
        category: "svečana"
    },
    {
        image: svecana67,
        category: "svečana"
    },
    {
        image: svecana68,
        category: "svečana"
    },
    {
        image: svecana69,
        category: "svečana"
    },
    {
        image: svecana70,
        category: "svečana"
    },
    {
        image: svecana71,
        category: "svečana"
    },
    {
        image: svecana72,
        category: "svečana"
    },
    {
        image: svecana73,
        category: "svečana"
    },
    {
        image: kamin1,
        category: "kamin"
    },
    {
        image: kamin2,
        category: "kamin"
    },
    {
        image: kamin3,
        category: "kamin"
    },
    {
        image: kamin4,
        category: "kamin"
    },
    {
        image: kamin5,
        category: "kamin"
    },
    {
        image: kamin6,
        category: "kamin"
    },
    {
        image: kamin7,
        category: "kamin"
    },
    {
        image: kamin8,
        category: "kamin"
    },
    {
        image: kamin9,
        category: "kamin"
    },
    {
        image: kamin10,
        category: "kamin"
    },
    {
        image: kamin11,
        category: "kamin"
    },
    {
        image: kamin12,
        category: "kamin"
    },
    {
        image: kamin13,
        category: "kamin"
    },
    {
        image: kamin14,
        category: "kamin"
    },
    {
        image: kamin15,
        category: "kamin"
    },
    {
        image: kamin16,
        category: "kamin"
    },
    {
        image: kamin17,
        category: "kamin"
    },
    {
        image: kamin18,
        category: "kamin"
    },
    {
        image: kamin19,
        category: "kamin"
    },
    {
        image: kamin20,
        category: "kamin"
    },
    {
        image: kamin21,
        category: "kamin"
    },
    {
        image: kamin22,
        category: "kamin"
    },
    {
        image: kamin23,
        category: "kamin"
    },
    {
        image: kamin24,
        category: "kamin"
    },
    {
        image: kamin25,
        category: "kamin"
    },
    {
        image: kamin26,
        category: "kamin"
    },
    {
        image: kamin27,
        category: "kamin"
    },
    {
        image: kamin28,
        category: "kamin"
    },
    {
        image: kamin29,
        category: "kamin"
    },
    {
        image: kamin30,
        category: "kamin"
    },
    {
        image: kamin31,
        category: "kamin"
    },
    {
        image: kamin32,
        category: "kamin"
    },
    {
        image: kamin33,
        category: "kamin"
    },
    {
        image: kamin34,
        category: "kamin"
    },
    {
        image: kamin35,
        category: "kamin"
    },
    {
        image: kamin36,
        category: "kamin"
    },
    {
        image: kamin37,
        category: "kamin"
    },
    {
        image: kamin38,
        category: "kamin"
    },
    {
        image: kamin39,
        category: "kamin"
    },
    {
        image: kamin40,
        category: "kamin"
    },
    {
        image: kamin41,
        category: "kamin"
    },
    {
        image: kamin42,
        category: "kamin"
    },
    {
        image: kamin43,
        category: "kamin"
    },
    {
        image: kamin44,
        category: "kamin"
    },
    {
        image: kamin45,
        category: "kamin"
    },
    {
        image: kamin46,
        category: "kamin"
    },
    {
        image: kamin47,
        category: "kamin"
    },
    {
        image: kamin48,
        category: "kamin"
    },
    {
        image: kamin49,
        category: "kamin"
    },
    {
        image: kamin50,
        category: "kamin"
    },
    {
        image: kamin51,
        category: "kamin"
    },
    {
        image: kamin52,
        category: "kamin"
    },
]